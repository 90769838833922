import React from "react";
import { BrowserRouter, Route, NavLink } from "react-router-dom";
import {
    NavBar
} from "./styles/styledElements.js";

function Navbar() {
  return (
    <NavBar>
      <div className="links">
   
            <li>
              < NavLink to="/" 
               >
                home
              </ NavLink>
            </li>
            <li>
              < NavLink to="/work" 
             >
                my work
              </ NavLink>
            </li>
            <li>
              < NavLink to="/resume" 
              >
                CV
              </ NavLink>
            </li>

      </div>
    </NavBar>
  );
}

export default Navbar;