import React from 'react';
import CV from '../images/CV.png';
import CV_pdf from '../images/CV2024.pdf';
import { ResumeBox } from "../styles/styledElements";

function Resume() {
    return (
        <ResumeBox>
            <img loading="lazy" src={CV} />  

            <p>
            <a href={CV_pdf} download>Download the CV as pdf</a>
            </p>
            
        </ResumeBox> 
    
    );
}

export default Resume;