import React from 'react';
import concept from '../images/nl_concept.jpeg';
import image from '../images/nl_image.jpeg';
import beginning from '../images/nl_beginning.png';
import logo from '../images/nl_logo.svg';
import concepts from '../images/nl_concepts.png';
import proto from '../images/NL_proto.png';
import { BrowserRouter, Route, NavLink } from "react-router-dom";
import target from '../images/target.png';
import { Presentation } from "../styles/styledElements";

function GoFika() {
    return (
        <Presentation>

            <div className='header'>
                <h1>Naisten Linja</h1>
                <p>Fall 2022</p>
            </div>

            <p className='description'>Naisten Linja provides counselling and support for women and girls who have experienced violence and harassment. 
            On the course Societal Design Project, we were assigned to design an online harassment self-defence concept for Naisten Linja.</p>

            
            <img src={image} />
            
            <h2>My contributions</h2>
            <p className='description'>We were a group of 4 Master's Students from Aalto University. Work was distributed equally, and teamwork was seamless. 
            I contributed by holding user interviews, brainstorming concepts, creating prototypes and validating them, among other things. </p>

         
            
            

            <h2>The process</h2>

            <p className='description'>The project started with us interviewing the client and trying to understand the problem they wanted us to solve. 
            The client wanted us to help people who identify as women to recognize, prevent and recover from online harassment. </p>

            <img src={beginning} />
            <p className='small'>Mind map by Bhuvana Sekar</p>

          
            <p className='description'>In order to understand out target group, we created an online survey which taught us that women are facing harassment online 
            and practicing self-censoring because of this. We also found out that women are not aware of Naisten Linja support services.
            </p>

            

             
            <p className='description'> The following video describes our process of understanding your target group.
            </p>


            <iframe width="560" height="315" src="https://www.youtube.com/embed/pL2KMU_gUw8?si=vXDIG8tIira3AmZK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

            <img loading="lazy"  src={target} />

            <p className='description'>This survey also helped us to recognize 3 target groups who face harassment online:

               
                <p>1. women who are active social media users</p>
                <p>2. women who are active video game players</p>
                <p>3. women who have a platform and are influencers</p>
                <br/>
                
            </p>

            <p className='description'>We interviewed women from all our target groups and learned that women are used to facing 
            harassment online but do not always recognize it as harassment. Women do a lot of safety work for example self-censoring.
            </p>


            <img loading="lazy"  src={concepts} />
           
            <p className='description'>We brainstormed concepts and ended up on two ideas. Creating a web portal called Turvaverkko, 
            for women to find support easily and efficiently and creating a marketing campaign to increase awareness on Naisten Linja.
            </p>

            <img loading="lazy"  src={concept} />
            <p className='small'> My concept sheet</p>

           


            <h2>The outcome</h2>
            <p className='description'>We created a prototype for the new Turvaverkko portal and validated it on users.
            </p>
            <p className='description'>Naisten Linja was very happy with the outcome and was interested in developing the portal further.
            </p>

            <img loading="lazy"  src={proto} />
            <p className='small'>The prototype of Turvaverkko</p>

            <p className='description'>We also won the “best pitch”- competition of the course Societal Design Project with our idea and presentation.</p>

            <img loading="lazy"  className="nl_logo" src={logo} />

            <NavLink to="/work"> go back</NavLink>
      
        </Presentation>
       
    );
}

export default GoFika;