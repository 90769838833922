import React from 'react';
import walk from '../images/fika_swipe.png';
import proto1 from '../images/proto1.png';
import proto3 from '../images/proto3.png';
import proto4 from '../images/proto4.png';
import swipe from '../images/swipe_img.png';
import mapimage from '../images/map_img.png';
import phonehand from '../images/phone_img.png';
import front from '../images/front.png';
import proto from '../images/proto.png';
import aurora from '../images/aurora.png';
import finalproto from '../images/final-proto.png';
import identity from '../images/visuals.png';
import { Presentation } from "../styles/styledElements";
import { BrowserRouter, Route, NavLink } from "react-router-dom";

function GoFika() {
    return (
        <Presentation>

            <div className='header'>
                <h1>goFika </h1>
                <p>Spring - Fall 2021</p>
            </div>

            

    
            <p className='description'> goFika was a coffee subscription service operating in Helsinki. Our mission was to unite cafes 
            and coffee lovers into a large network. We wanted to make the cafe experience effortless and fun for our customers as well as to accelerate the additional sales of our partner cafes.</p>
            
            <div className="gofika_images">

                <img loading="lazy"  className="hand" src={phonehand} />
                <img loading="lazy"  src={mapimage} />
                <img loading="lazy"  src={swipe} />
 
            </div>

            <h2>My contributions</h2>
            
            <p className='description'> goFika was a student led startup found in the beginning of 2021. I was one of the founders and in charge of creating 
            the brand, the visuals, the user experience and layout for the app. I designed and developed the homepage for goFika and did marketing and social media.</p>

            
          
            <img loading="lazy"  className='guy' src={walk} />

            <h2>The process</h2>

            <p className='description'>My work with goFika started by creating a user persona for the service. </p>


            <ul>
                <li>The user is:</li>
                <br/>
                <li>1. 20-30 years-old</li>
                <li>2. Lives/works in Helsinki city center</li>
                <li>3. Enjoys good coffee</li>
                <li>4. Wants things to be easy and efficient</li>
            </ul>
            <img loading="lazy"  className='gofika' src={aurora} />


            <p className='description'>
              I wanted to create a brand and visual identity which is easily approachable, youthful and fun.
            </p>

            <img className='gofika-wide' src={identity} />

            <p className='description'>Building the product started by me looking at references and building a simple prototype to test. 
            I conducted user testing on users from our target group and developed the prototype based on results. This was an iterative process.</p>

            <img loading="lazy"  className='gofika' src={proto} />

            <p className='description'>I created the final protype using Adobe XD and visuals with 
            Adobe Illustrator and Photoshop.</p>

            <img loading="lazy"  className='gofika' src={finalproto} />

            <p className='description'>Once the prototype was "good enough" 
            we started building the UI with the developer. Some changes to the final prototype were made.</p>

            <img loading="lazy"  className='gofika' src={front} />


            <p className='description'>The final product was launched on App Store and Google Play store.</p>
        

            <h2>How does the app work?</h2>

            <div className="points">
                <div>
                    
                    <img loading="lazy"  className="proto" src={proto1} />
                    <p>Get the app</p>
                </div>
  
                <div>
                    
                    <img loading="lazy"  className="proto" src={proto4} />
                    <p>Find a cafe you would like to visit</p>
                </div>
  
                <div>
                   
                    <img loading="lazy"  className="proto" src={proto3} />
                    <p>Grab a cup of coffee effortlessly</p>
                </div>
             
            </div>

            <NavLink to="/work"> go back</NavLink>


    

        </Presentation>
       
    );
}

export default GoFika;