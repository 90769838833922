import React from 'react';
import swipe from '../images/fika_swipe.png';
import { Presentation } from "../styles/styledElements";
import rebase_visu from '../images/landing-image-right.png';
import rebase1 from '../images/rebase1.png';
import rebase2 from '../images/rebase2.png';
import rebase3 from '../images/rebase3.png';
import { BrowserRouter, Route, NavLink } from "react-router-dom";

function GoFika() {
    return (
        <Presentation>

            <div className='header'>
                <h1>Rebase Consulting</h1>
                <p>Spring - Fall 2021</p>
            </div>

          
            
    
            <p className='description'>Rebase is a software consulting company that provides solutions for organisations in their digital journey.</p>


            <img loading="lazy"  src={rebase_visu} />

            <h2>My contributions</h2>
            
            <p className='description'>I was assigned to update the UI and create new visuals for the website of Rebase. 
            I created the visuals with Adobe Illustrator and implemented the updates with React.</p>
          
        
            <h2>The product</h2>
            <img loading="lazy"  src={rebase1} />
            <img loading="lazy"  src={rebase2} />
            <img loading="lazy"  src={rebase3} />

            <NavLink to="/work"> go back</NavLink>
    
        </Presentation>
       
    );
}

export default GoFika;