import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Work from "./pages/Work";
import Resume from "./pages/Resume";
import GoFika from "./pages/GoFika";
import NL from "./pages/NL";
import Rebase from "./pages/Rebase";
import Visuals from "./pages/Visuals";
import Photos from "./pages/Photos";
import NoPage from "./pages/NoPage";
import ScrollToTop from "./ScrollToTop";

export default function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="work" element={<Work />} />
            <Route path="resume" element={<Resume />} />
            <Route path="gofika" element={<GoFika />} />
            <Route path="naistenlinja"  element={<NL />} />
            <Route path="rebase"  element={<Rebase />} />
            <Route path="visuals" element={<Visuals />} />
            <Route path="photos" element={<Photos />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));