import React from 'react';
import swipe from '../images/fika_swipe.png';
import { Presentation } from "../styles/styledElements";
import clubx from '../images/visut/ClubXposter.jpg';
import linkedin from '../images/visut/Linkedin.png';
import tanssi from '../images/visut/tanssijuliste.jpg';
import fika from '../images/visut/gofika_poster.jpg';
import swipeImage from '../images/visut/fika_swipe.png';
import ukkeli from '../images/visut/ukkeli2_2.png';
import avatar1 from '../images/visut/avatar4.png';
import avatar2 from '../images/visut/avatar7.png';
import avatar3 from '../images/visut/gofore_kokoavatar.png';
import maapallo from '../images/visut/käsipallo.png';
import phone from '../images/visut/iphone.png';
import self from '../images/visut/selfportrait.jpg';

import { BrowserRouter, Route, NavLink } from "react-router-dom";

function GoFika() {
    return (
        <Presentation>

            <div className='header'>
                <h1>Visual work</h1>
            </div>
    
            <p className='description'>I mostly use Adobe Illustartor, Photoshop and Indesign. All drawings and visuals are by me. </p>


            <h2>Vector graphics</h2>

            <div className='illustrations'>

                <div className='avatars'>
                    <img src={avatar1} />
                    <img src={avatar2} />
                </div>
                <div className='avatars'>
                    <img src={swipeImage} />
                    <img src={ukkeli} />
                </div>
                <div className='vectors'>
                    <img src={avatar3} />
                    <img src={phone} />
                    <img src={maapallo} />
                </div>
              
                
                


            </div>

          
            <h2>Layout design</h2>

            <div className='posters'>

                <img loading="lazy" src={tanssi} />

                
                <img loading="lazy" src={clubx} />
             
                <img loading="lazy" src={fika} />

                <img loading="lazy" src={self} />

            </div>

            <NavLink to="/work"> go back</NavLink>


            
        </Presentation>
       
    );
}

export default GoFika;