import React from 'react';
import { MyWork } from "../styles/styledElements";
import { BrowserRouter, Route, NavLink } from "react-router-dom";
import gofika from '../images/gofika.png';
import rebase from '../images/rebase.png';
import nl from '../images/nl.png';
import visut from '../images/visu.png';
import photos from '../images/images.png';


function Work() {
    return (
        <MyWork>
            <div className='WorkBoxOdd'>
                <NavLink to="/gofika"><img loading="lazy" src={gofika} alt="gofika logo"/> </NavLink>
                <p>Brand and UI/UX design work for goFika</p>
            </div>
            <div className='WorkBoxEven'>
                <NavLink to="/naistenlinja"><img loading="lazy" src={nl} alt="naistenlinja logo"/></NavLink>
                <p>Service design work for Naisten Linja</p>
            </div>
            <div className='WorkBoxOdd'>
                <NavLink to="/rebase"><img loading="lazy" src={rebase} alt="rebase logo"/></NavLink>
                <p>UI design work for Rebase Consulting</p>
            </div>
            <div className='WorkBoxEven'>
                <NavLink to="/visuals"><img loading="lazy"  src={visut} alt="globe and phone"/></NavLink>
                <p>My visual design work</p>
            </div>
            <div className='WorkBoxOdd'>
                <NavLink to="/photos"><img loading="lazy" src={photos} alt="image of a red house"/></NavLink>
                <p>My photography</p>
            </div>
            
        </MyWork>
       
    );
}

export default Work;