import React from 'react';
import { FooterItem } from "../styles/styledElements";

function Footer() {
    return (
        <FooterItem>
            <p>
                Wanna talk?
            </p>
            <p>
                +358 40 4151425 <br />
                anna.karolina.m(at)hotmail.com <br />
                <a href="https://www.linkedin.com/in/anna-karolina-m%C3%BCller/"> Linkedin </a>
                
            </p>
            <p className='small'>©2024 Anna Müller.</p>
        </FooterItem>
        
    );
}

export default  Footer;