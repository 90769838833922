import styled from "styled-components";



export const LayOut = styled.div`

    padding: 1rem 20%;
    
    .example-enter {
        opacity: 0;
        transform:translateX(-100%);
      }
      .example-enter-active {
        opacity: 1;
        transform:translateX(0);
        transition: opacity 300ms, transform 300ms;
      }
      .example-exit {
        opacity: 1;
        transform:translateX(0);
      }
      .example-exit-active {
        opacity: 0;
        transform:translateX(-100%);
        transition: opacity 300ms, transform 300ms;
      }

    .content {
        display: grid;

    }

    p {
        font-family: 'Montserrat', sans-serif;
        font-size: 1.5rem;
        color: #363636;
        font-weight: 400;
        line-height: 1.9;
    }

    ul {
        font-family: 'Montserrat', sans-serif;
        font-size: 1.5rem;
        color: #515151;
        font-weight: 600;
        line-height: 1.9;
        line-height: 160%;
        font-weight: 400;
        list-style-type: none; 
    }
    h1 {
        font-family: 'Poppins', sans-serif;
        font-weight: 700;
        font-size: 4.9rem;
        color: #363636;
        margin: 0rem;
    }
    

    h2 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 2.2rem;
        color: #363636;
        margin: 0rem;
    }

    a {
        font-family: 'Poppins', sans-serif;
        text-decoration: none;
        color: #A020F0;
    }

    a:hover {
        text-decoration: underline;
        color: #A020F0;
    }

    @media only screen and (max-width: 2000px) {
        padding: 1rem 12%;
    }


    @media only screen and (max-width: 1400px) {
        padding: 1rem 5%;
    }


    @media only screen and (max-width: 1000px) {

        padding: 0 1rem;

        p {
            font-size: 1rem;
            font-weight: 500;
        }

        a {
            font-size: 1rem;
        }
    
        h1 {
            font-size: 2.5rem;
        }
        
    
        h2 {
            font-size: 1.2rem;
            font-weight: 500;
        }
    
        .content {
            display: flex;
            flex-direction: column;
            
        }

        ul {
            font-size: 0.9rem;
            font-weight: 500;
        }

        iframe {
            width: 100%;
            height: 100%;
        }
    }
`

export const BannerItem = styled.div`

    display: flex;
    flex-direction: row;
    border-bottom: 2px solid #515151;
    margin: 1em 0;
    padding: 1em 0;
    z-index: 1;

    svg {
        fill: #363636;
        padding: 0.1em 0 0 0.4em;
        margin: auto;
    }

    svg: hover {
        fill: #A020F0;
    }

    a {
        color: #363636;
        font-weight: 500;
        font-size: 1.9em;
    }

    @media only screen and (max-width: 1000px) {
        border-bottom: 1px solid #515151;
        margin: 0.5em 0;
        padding: 0.5em 0;

        a {
            color: #363636;
            font-weight: 600;
            font-size: 1.4em;
        }
        
        svg {
            fill: #363636;
            padding: 0 0 0.2em 0.5em;
            margin: auto;
            width: 70%
        }

        
    }

`


export const FooterItem = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10rem;
    text-align: right;
    p {
        margin-top: 4rem;
    }

    .small {
        font-size: 1rem;
        font-weight: 500;
    }

    @media only screen and (max-width: 1000px) {

        margin-top: 3rem;

        p {
            margin-top: 1rem;
        }
       
    }

`

export const NavBar = styled.div`

    display: flex;
    flex-direction: row;
    grid-column: 1 / 3;
    position: fixed;
    top: 0;

    .links {
        padding: 18rem 0rem;

        li {
            margin: 1rem 0;
            list-style: none;
            animation-duration: 1s;
            animation-name: slidein;
            
    
            @keyframes slidein {
                from {
                margin-left: -200%;
                width: 300%;
                }
            
                to {
                margin-left: 0;
                width: 100%;
                }
            }
        }

        a:-webkit-any-link {
            text-decoration: none;
            color: #363636;
            cursor: pointer;
            font-size: 2rem;
            margin-bottom: 1rem;
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
        }
       
        a:hover {
            text-decoration: line-through; 
            text-decoration-color: 
#A020F0;
            color: 
#A020F0;
        }


        a[aria-current="page"]{
            text-decoration: line-through;
        } 
       
    }


    @media only screen and (max-width: 1400px) {


        .links {

            padding: 20rem 0rem;

        }
        
    }

    @media only screen and (max-width: 1000px) {

        display: flex;
        flex-direction: row;
    
        position: inherit;
    
        .links {
            padding: 0.5rem 0rem; 
            
            a:-webkit-any-link {
                font-size: 1.7rem;
            }
        }
    
       
    }


`
export const MainCotainer = styled.div`

    grid-column: 2 / 3;
    justify-items: end;
    justify-content: end;
    margin-left: 20%;


    @media only screen and (max-width: 1000px) {
        margin-left: 0%;
    }

`

export const HomePage = styled.div`


    text-align: right;
    right: 0;
    margin-right: 0;
    padding-right: 0;

    p, h2, h1{
        animation-duration: 1s;
        animation-name: slidein;
    }

    p {
        margin-left: 3rem;
    }

    img {
        width: 40%;
        margin-right: 5rem;
        animation-duration: 2s;
        animation-name: slidein-image;
    }
   
    h1, img, p {
        margin-top: 8rem;
        
    }

    @keyframes slidein-image {
        from {
            margin-left: 100%;
            width: 40%;
        }
    
        to {
            margin-left: 0%;
            width: 40%;
        }
    }

    @keyframes slidein {
        from {
        margin-left: 100%;
        width: 100%;
        }
    
        to {
        margin-left: 0%;
        width: 100%;
        }
    }

    @media only screen and (max-width: 1000px) {

        img, h1 {
            margin-right: 0;
        }

        h1, img, p {
            margin-top: 2rem;
        }

        img {
            width: 80%;
        }

        p {
            margin-left: 0rem;
            font-size: 1em;
        }

            
        @keyframes slidein-image {
            from {
                margin-left: 100%;
                width: 80%;
            }
        
            to {
                margin-left: 0%;
                width: 80%;
            }
        }
        @keyframes slidein {
            from {
                margin-left: 100%;
                width: 100%;
            }
        
            to {
                margin-left: 0%;
                width: 100%;
            }
        }
    }

`

export const MyWork = styled.div`

    animation-duration: 1s;
    animation-name: slidein;


    @keyframes slidein {
        from {
        margin-left: 100%;
        width: 300%;
        }
    
        to {
        margin-left: 0%;
        width: 100%;
        }
    }

    display: flex;
    flex-direction: column;
    margin-top: 5rem;

    align-items: end;

    img {
        width: 25rem;
        height: 25rem;
    }

    .WorkBoxOdd, .WorkBoxEven {
        margin: 0rem;
        margin-bottom: 4rem;
    }
    .WorkBoxEven {
        margin-right: 30rem;
    }

    p {
        text-align: right;
        font-size: 1.2rem;
        font-weight: 500;
    }

    @media only screen and (max-width: 1500px) {


        img {
            width: 20rem;
            height: 20rem;
            margin-bottom: 0rem;
        }
        .WorkBoxEven {
            margin-right: 20rem;
        }
        p {
            font-size: 1rem;
            font-weight: 500;
        }
    
        
    }

    @media only screen and (max-width: 1000px) {

        margin-top: 2rem;
        align-self: center;
        align-items: center;
        text-align: center;

        img {
            width: 15rem;
            height: 15rem;
        }

        .WorkBoxOdd, .WorkBoxEven {
            margin: 2rem;
        }
        
        p {
            font-size: 0.8rem;
            font-weight: 500;
        }
    }
`


export const Presentation = styled.div`

    text-align: right;


    .header {
        
        margin-bottom: 5rem;
        a {
            margin: 5em 0rem 0rem 0rem;
            font-family: 'Montserrat', sans-serif;
            font-weight: 500;
            font-size: 1.5rem;
            align-self: left;
            align-items: left;
            text-align: left;
        }

        p {
            margin: 0rem;
        }
    }


    a {
        margin: 5em 0rem;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        font-size: 1.5rem;
    }


    .guy {
        width: 40%;
    }

    .gofika {
        width: 50%;
    }

    .gofika-wide {
        width: 80%;
    }

    .nl_logo {
        width: 10rem;
    }


    .small {
        font-weight: 400;
        font-size: 1.2rem;
        margin-top: 0rem;
    }


    img {
        max-width: 50rem;
    }
   
    h1, h2 {
        margin-top: 8rem;
    }

    img {
        margin: 4rem 0rem;
    }

    p {
        margin-top: 2rem;
    }


    .description {
        margin-top: 2rem;
        font-weight: 400;
        margin-left: 4rem;
    }

    .gofika_images{
        img {
            width: 20rem;
            margin-left: 2rem;
        }
        .hand {
            width: 15rem;
        }

    }
    .points {
        display: flex;
        flex-direction: row;
        justify-content: right;
        img {
            width: 16rem;
            margin-left: 2rem;
        }
        p {
            margin-top: 0rem;
            width: 15rem;
            font-size: 1rem;
            text-align: center;
            margin-left: 2rem;
        } 
    }

    .visuals {
        display: flex;
        flex-direction: column;
        


        img {
            width: 100%;
            align-self: end;    
        }
    }

    .photos {
        
        img {
            width: 90%;
        }
    }

    .posters {
        img {
            width: 60%;
            margin-top: 10rem;
        }
    }


    .illustrations {

        img {
            max-width: 40rem;
            margin-left: 10rem;
            margin-top: 10rem;

        }

        .avatars {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            
            img {
                margin-left: 3rem;
                max-height: 25rem;
            }
        }

    }



    @media only screen and (max-width: 1600px) {

        .points {
            img {
                width: 13rem;
            }
            p {
                margin-top: 0rem;
                width: 11rem;
                font-size: 1rem;
                text-align: center;
            } 
        }

        .gofika_images{
            img {
                width: 13rem;
                margin: 0.5rem;
            }
            .hand {
                width: 5rem;
            }
    
        }
    }



    @media only screen and (max-width: 1000px) {

        margin-left: 0rem;

        .header {
    
            a {
                margin-top: 1rem;
                font-size: 1rem;
            }
        }

        .points {
            display: flex;
            flex-direction: column;

            > div {

                align-self: flex-end;

                img {
                    width: 8rem;
                }
                p {
                    margin: 0rem;
                    text-align: right;
                }

            }
   
        }

        .small {
            font-weight: 400;
            font-size: 0.8rem;
        }
    

        .gofika_images{
            img {
                width: 6rem;
                margin: 0.5rem;
            }
            .hand {
                width: 5rem;
            }
    
        }

        img {
            max-width: 100%;
            margin: 2rem 0rem;
   
        }
       
        h1, h2, img{
            margin-top: 2rem;
        }
    
        p {
            margin-top: 1rem;
            margin-right: 0;
        }

        .posters {
            img {
                width: 100%;
                margin-top: 2rem;
            }
        }
    

        .illustrations {

            img {
                max-width: 15rem;
                margin-left: 0rem;
    
            }
            .avatars {
                display: flex;
                flex-direction: column;
                
                img {
                    margin-left: 3rem;
                    max-height: 30rem;
                }
            }
    
        }

        
        .photos {
            text-align: center;
            
            img {
                width: 90%;
            }
        }

    
        
    }
`

export const ResumeBox = styled.div`

align-items: end;
text-align: right;
align-self: end;
animation-duration: 1s;
animation-name: slidein;


@keyframes slidein {
    from {
    margin-left: 100%;
    width: 100%;
    }

    to {
    margin-left: 0%;
    width: 100%;
    }
}


img {
    width: 80%;
    margin-top: 4rem;s
    align-self: end;
}

@media only screen and (max-width: 1000px) {
    align-items: center;
    text-align: center;
    align-self: center;

    img {
        width: 20rem;
        margin: 2rem 0rem;
    }

}


   
`