import React from 'react';
import { Presentation } from "../styles/styledElements";
import image1 from '../images/photos/image1.JPG';

import image3 from '../images/photos/image3.JPG';
import image4 from '../images/photos/image4.JPG';

import image5 from '../images/photos/image5.jpg';
import image6 from '../images/photos/image6.JPG';
import image7 from '../images/photos/image7.JPG';
import image8 from '../images/photos/image8.jpg';
import image9 from '../images/photos/image9.jpg';
import image10 from '../images/photos/image10.jpg';
import image11 from '../images/photos/image11.jpg';
import image12 from '../images/photos/image12.JPG';
import image13 from '../images/photos/image13.jpg';
import image14 from '../images/photos/image14.jpg';
import image15 from '../images/photos/image15.JPG';
import image16 from '../images/photos/image16.JPG';
import image17 from '../images/photos/image17.JPG';
import image18 from '../images/photos/image18.jpg';
import { BrowserRouter, Route, NavLink } from "react-router-dom";

function GoFika() {
    return (
        <Presentation>





            <div className='header'>
                <h1>Photography </h1>
            </div>

            <p className='description'>Here are some photos taken from along the years. Photos 2-7 are shot with an analogue camera.</p>


            <div className='photos'>
                <img src={image1} />
             
                <img loading="lazy" src={image4} />
                <img loading="lazy" src={image5} />
                <img loading="lazy" src={image6} />
                <img loading="lazy" src={image7} />
                <img loading="lazy" src={image8} />
                <img loading="lazy" src={image9} />
                <img loading="lazy" src={image12} />
                <img loading="lazy" src={image18} />
                <img loading="lazy" src={image13} /> 
                <img loading="lazy" src={image14} /> 
                <img loading="lazy" src={image15} />
                <img loading="lazy" src={image17} /> 
                <img loading="lazy" src={image3} />
                

            </div>

            <NavLink to="/work"> go back</NavLink>


        </Presentation>
       
    );
}

export default GoFika;