import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../Navbar";
import  Banner from "./Banner"
import  Footer from "./Footer"
import { 
    LayOut,
    MainCotainer
 } from "../styles/styledElements";



const Layout = () => {


  
  return (
    <LayOut>
      <Banner/>
      <div className="content">
        <Navbar />       
        <MainCotainer>
            < Outlet />
        </MainCotainer>
      </div>
      <Footer/>
    </LayOut>
  );
};

export default Layout;