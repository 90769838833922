import React from 'react';
import Me from '../images/me2.png';
import Sign from '../images/sign.png';
import { HomePage } from "../styles/styledElements";
import { BrowserRouter, Route, NavLink } from "react-router-dom";
import Animate from 'animate.css-react'

function Home() {
    return (
        <HomePage>

            <h1>Hi, it's Anna Müller</h1>
            <h2>I do UI/UX design and visuals</h2>

            <img src={Me} />  
            <p>
                I’m a user centric designer and Master of Science from Helsinki. I’m excited about creating digital products and 
                services that are user friendly and functional, while creating a positive impact to the society.
                I have gathered up some of my work from along the years in my <NavLink to="/work"> portfolio. </NavLink>
                This page is designed and developed by me, using React. 
                Make sure to check out my  <NavLink to="/resume"> resume </NavLink> also!
            </p>
            <img loading="lazy" src={Sign} alt="anna muller sitting"/>  
        </HomePage>
       
    );
}

export default Home;